import BeaconAction from '@tencent/beacon-web-sdk';
const success = (e) => {
  console.log('onReportSuccess : ', e);
};
const fail = (e) => {
  console.log('onReportFail : ', e);
};

export const beacon = new BeaconAction({
  appkey: window.ENV_CONSTANT.BEACON_APP_KEY ?? '0DOU0WB39S47NV5Q', // 系统或项目id, 必填
  versionCode: '2.11.1', // 项目版本,选填
  channelID: 'channel', // 渠道,选填
  openid: 'jojo', // 用户id, 选填
  isDebug: false, // 是否开启实时联调, 默认关闭,选填
  strictMode: false, // 严苛模式开关, 打开严苛模式会主动抛出异常, 上线请务必关闭!!!
  delay: 3000, // 普通事件延迟上报时间(单位毫秒), 默认3000(3秒),选填
  // session变更的时间间隔, 一个用户持续30分钟(默认值)没有任何上报则算另一次 session
  // 每变更一次session上报一次启动事件(rqd_applaunched),使用毫秒(ms),最小值30秒,选填
  sessionDuration: 60 * 1000,
  onReportSuccess: success, // 上报成功回调,选填
  onReportFail: fail, // 上报失败回调,选填
});

function getQueryArgs() {
  const hash = window.location.hash.substring(1); // "/tux/NPS?survey_id=134&uuid=xxx"
  const obj = hash.split('?')?.[1]?.split("&")?.reduce(function (prev, curr) {
    const pair = curr.split("=");
    prev[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    return prev;
  }, {}) ?? {};
  return obj;
}

/**
 * 上报用户行为
 * @param {string} action_name 行为名称，必填
 * @param {string | undefined} action_value 行为结果，可不填
 */
export const reportUserAction = (action_name, action_value) => {
  if (action_name) {
    let survey_type = '';
    const hash = window.location.hash.substring(1)
    if (hash.toLocaleLowerCase().indexOf('csat') > -1) {
      survey_type = 'csat';
    }
    if (hash.toLocaleLowerCase().indexOf('nps') > -1) {
      survey_type = 'nps';
    }
    const queryArgs = getQueryArgs();
    const { uuid, survey_id } = queryArgs ?? {};
    beacon.onDirectUserAction('tux_h5_user_action', {
      env: window.ENV_CONSTANT.CLINT_ENV,
      iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
      // @ts-ignore
      tux_uuid: uuid,
      // @ts-ignore
      survey_id,
      survey_type,
      server_type: 'H5',
      action_name,
      action_value,
      app_id: queryArgs?.app_id ?? 'qb',
    })
  }
}
