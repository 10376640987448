/*
 * @Author: wesli
 * @Date: 2021-09-16 19:52:39
 * @Last Modified by: wesli
 * @Last Modified time: 2023-05-15 15:33:13
 */

import { magicConchAxiosHelper, MAGIC_CONCH_ACTIONS } from '@/api/magic-conch';
import { IResResult, IResResultT } from '@/interface/common';
import _ from 'lodash';

export enum EFileSaveType {
  ALWAYS = 'ALWAYS',
  CACHED = 'CACHED',
}

export interface IFileInfo {
  id: number;
  name: string;
  content: string;
  created_by: string;
  save_type: EFileSaveType;
  create_date: string;
  update_date: string;
}

/**
 *  根据定时添加文件缓存到数据库
 * @param fileName 文件名
 * @param content 内容
 * @param createdBy 创建人
 * @param saveType 保存类型
 * @returns
 */
export async function addFile(
  fileName: string,
  content: string,
  createdBy: string,
  saveType: EFileSaveType,
): Promise<IResResultT<number>> {
  const res = await magicConchAxiosHelper.post<IResResultT<number>>(MAGIC_CONCH_ACTIONS.add, {
    fileName,
    content,
    createdBy,
    saveType,
  });
  return res;
}

/**
 *  删除文件列表
 * @param fileName 文件名
 * @param createdBy 创建人
 * @param saveType 保存类型
 * @returns
 */
export async function deleteFiles(
  fileName: string,
  createdBy: string,
  saveType: EFileSaveType,
): Promise<IResResult> {
  const res = await magicConchAxiosHelper.post<IResResult>(MAGIC_CONCH_ACTIONS.delete, {
    fileName,
    createdBy,
    saveType,
  });
  return res;
}

export async function getFileInfo(
  fileName: string,
  saveType: 'ALWAYS' | 'CACHED',
  createdBy: string,
): Promise<IFileInfo | undefined> {
  const res = await magicConchAxiosHelper.get<IResResultT<IFileInfo>>(MAGIC_CONCH_ACTIONS.getInfo, {
    params: {
      fileName,
      saveType,
      createdBy,
    },
  });
  if (res.code === 0 && _.isPlainObject(res.data)) {
    return res.data;
  }
  return undefined;
}

export interface IGetFilesRes {
  code: number;
  data?: IFileInfo[];
  total: number;
  msg?: string;
}

/**
 * 根据文件名、保存类型和分页属性获取文件列表
 * @param fileName
 * @param saveType
 * @param pageIndex
 * @param pageSize
 * @returns
 */
export async function getFiles(
  fileName: string,
  saveType: 'ALWAYS' | 'CACHED',
  pageIndex = 0,
  pageSize = 10,
): Promise<IGetFilesRes> {
  const res = await magicConchAxiosHelper.get<IGetFilesRes>(MAGIC_CONCH_ACTIONS.getList, {
    params: {
      fileName,
      saveType,
      pageIndex,
      pageSize,
    },
  });
  const data = res?.data
    ? res.data
    : [];
  return { ...res, data };
}

/**
 * 根据id获取文件信息
 * @param id
 * @returns
 */
export async function getFileById(id: string | number): Promise<IFileInfo | undefined> {
  const res = await magicConchAxiosHelper.get<IResResultT<IFileInfo>>(`${MAGIC_CONCH_ACTIONS.getInfo}/${id}`);
  if (res.code === 0) {
    return res.data;
  }
  return undefined;
}
