import { defineComponent, PropType } from '@vue/composition-api';
import './index.less';

/**
 * Checkbox选择组件
 */
export default defineComponent({
  name: 'CusCheckbox',
  props: {
    onChange: Function as PropType<(value: boolean) => void>,
    onClick: Function as PropType<() => void>,
    checked: Boolean,
    disabled: Boolean,
    value: {
      type: [String, Number, Boolean],
    },
  },
  setup() {
    return {
    };
  },
  render() {
    const { checked } = this.$props;
    return <label class='cus-checkbox' onClick={() => {
      this.$emit('change', !checked);
      this.$emit('click');
    }}>
      <span>
        {
          checked
            ? <t-icon
              name='check-rectangle'
              class='fs-20px cus-checkbox-selected cus-checkbox-icon'
            />
            : <t-icon
              name='rectangle'
              class='fs-20px c-666 cus-checkbox-icon'
            />
        }
      </span>
      <span class='cus-checkbox-text'>{this.$slots.default}</span>
    </label>;
  },
});
