import { defineComponent, PropType } from '@vue/composition-api';
import './index.less';
import { CheckCircleFilledIcon, CircleIcon } from 'tdesign-icons-vue';

/**
 * Radio选择组件
 */
export default defineComponent({
  name: 'CusRadio',
  props: {
    onChange: Function as PropType<(value?: string | number | boolean) => void>,
    checked: Boolean,
    disabled: Boolean,
    value: {
      type: [String, Number, Boolean],
    }
  },
  setup() {
    return {
    };
  },
  render() {
    const { checked } = this.$props;
    return <label class='cus-radio' onClick={() => {
      this.$emit('change', !checked);
    }}>
      <span>
        {
          checked
            ? <CheckCircleFilledIcon
              class='fs-20px cus-radio-selected'
            />
            : <CircleIcon
              class='fs-20px c-666'
            />
        }
      </span>
      <span class='cus-radio-text'>{this.$slots.default}</span>
    </label>;
  },
});
