import { initializeQuestions } from '@/api/survey';
import { IFileInfo, getFileById } from '@/bus/use-magic-conch-file';
import CusLoading from '@/components/CusLoading';
import { Col, Row } from '@/components/Grid';
import QuestionPreviewer from '@/components/QuestionPreviewer';
import { EQuestionItemType, IQuestionItem } from '@/interface/survey';
import { useRef } from '@/utils/composition-helper';
import { convert2SurveyQuestions } from '@/utils/survey-helper';
import { computed, defineComponent, onMounted, watch } from '@vue/composition-api';
import _ from 'lodash';
import { MessagePlugin } from 'tdesign-vue';
import { useRoute } from 'vue2-helpers/vue-router';
import './index.less';

/**
 * 问卷预览页面
 */
export default defineComponent({
  name: 'SurveyPreview',
  props: {
  },
  setup() {
    const route = useRoute();
    const [fileInfo, setFileInfo] = useRef<IFileInfo | undefined>();
    const [loading, setLoading] = useRef(false);
    const [q36, setQ36] = useRef<string | undefined>();
    const fileInfoId = computed(() => {
      const fileInfoId: string | undefined = route.query.fileInfoId as string | undefined;
      return fileInfoId;
    });
    const fetchFn = async () => {
      if (fileInfoId.value) {
        setLoading(true);
        const model: IFileInfo | undefined = await getFileById(fileInfoId.value);
        setFileInfo(model);
        setLoading(false);
      }
    };
    watch(fileInfoId, () => {
      fetchFn();
    }, { immediate: true });
    const questions = computed(() => {
      if (fileInfo.value) {
        const { questions }: { questions: IQuestionItem[] } = JSON.parse(fileInfo.value.content);
        // 预览的数据包含被软删除的数据
        const newQuestions = questions.map(c => {
          if (c.type === EQuestionItemType.Star || c.type === EQuestionItemType.Option) {
            const options = c.options.filter(c => c.is_deleted !== true);
            return { ...c, options };
          }
          if (c.type === EQuestionItemType.Matrix) {
            const options = c.options.filter(c => c.is_deleted !== true);
            return { ...c, options };
          }
          return c;
        })
        const arr = initializeQuestions(newQuestions);
        // 显示的问卷问题需要单独根据各种问题类型做预处理操作
        return convert2SurveyQuestions(arr);
      }
      return [];
    });
    const uuid = computed(() => {
      const taskinfo_id = route.query.taskinfo_id as string | undefined;
      // 如果有传递task信息的id过来，则使用uuid拼接
      if (taskinfo_id && q36.value) {
        // 补充id为6位
        const left = _.padStart(taskinfo_id, 6, '0');
        // 拼接uuid
        // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
        return `${left}-${q36.value}`;
      }
      return route.query.uuid as string ?? '';
    });
    watch(uuid, (newVal) => {
      if (newVal) {
        setTimeout(() => {
          MessagePlugin.success(`当前uuid为: ${newVal}`);
        }, 3000);
      }
    }, { immediate: true });
    onMounted(() => {
      window.browser?.app?.getBrowserParam((pararms) => {
        const { qimei36 } = pararms;
        setQ36(qimei36);
        MessagePlugin.success(`当前q36为: ${qimei36}`);
      }, (error) => {
        MessagePlugin.error(JSON.stringify(error));
      });
    });
    return {
      questions,
      loading,
    };
  },
  render() {
    if (this.loading) {
      return <div style='height: calc(100vh);width: calc(100vw);'>
        <Row class='w-100-p h-100-p' justify='center' align='middle' gutter={8}>
          <Col>
            <CusLoading />
          </Col>
          <Col class='c-pri-6 fs-16px fw-5'>
            加载问卷内容中...
          </Col>
        </Row>
      </div>;
    }
    return <div style='height: calc(100vh);width: calc(100vw);'>
      <QuestionPreviewer questions={this.questions} />
    </div>;
  },
});
