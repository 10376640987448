import { useRef } from '@/utils/composition-helper';
import { PropType, defineComponent, onBeforeUnmount, onMounted } from '@vue/composition-api';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import './index.less';

export default defineComponent({
  name: 'ImageViewer',
  props: {
    imageUrl: {
      type: String as PropType<string>,
      required: true
    },
    renderContent: Function as PropType<() => JSX.Element>,
  },
  setup() {
    const [imageContainer] = useRef<HTMLDivElement | null>(null);
    let viewer: Viewer | null = null;

    const openViewer = () => {
     if (viewer) {
       viewer.show();
     }
    };

    onMounted(() => {
      if (imageContainer.value) {
        viewer = new Viewer(imageContainer.value, {
          inline: false,
          navbar: false,
          toolbar: {
            zoomIn: false,
            zoomOut: false,
            oneToOne: false,
            reset: true,
            prev:false,
            play: false,
            next:false,
            rotateLeft: true,
            rotateRight: true,
            flipHorizontal: true,
            flipVertical: true,
          },

          viewed() {
            // viewer?.zoomTo(1);
          },
        });
      }
    });

    onBeforeUnmount(() => {
      if (viewer) {
        viewer.destroy();
        viewer = null;
      }
    });
    return {
      imageContainer,
      openViewer,
    };
  },
  render() {
    return <div ref='imageContainer' class="image-viewer-container" onClick={this.openViewer}>
      {
        this.$slots.default
          ? this.$slots.default
          : this.$props.renderContent && this.$props.renderContent()
      }
      <img src={this.$props.imageUrl} style={{ display: 'none' }} />
    </div>;
  }
});
