import Aegis from '@tencent/aegis-web-sdk-v2';
// 线上环境存在AEGIS_TOKEN变量才上报到伽利略
if (!window.ENV_CONSTANT.IS_LOCAL && window.ENV_CONSTANT.AEGIS_TOKEN) {
  window.AegisV2 = new Aegis({
    id: window.ENV_CONSTANT.AEGIS_TOKEN, // 项目token，即上报伽利略监控的唯一标识
    hostUrl: {
      url: 'https://galileotelemetry.tencent.com/collect',
    },
    plugin: {
      device: true, // 默认开启device收集器
      close: true, // 默认开启close收集器
      aid: true, // 默认开启aid收集器
      fId: false, // 默认关闭fingerId
      ie: false, // 默认关闭ie收集器

      // 页面访问
      pv: true, // 默认开启pv收集器
      spa: true, // 默认开启spa收集器

      // 勾选异常分析
      error: true, // 默认开启error收集器

      // 勾选静态资源监控
      assetSpeed: true, // 默认开启assetSpeed收集器

      // 勾选页面性能
      pagePerformance: true, // 默认开启pagePerformance收集器
      webVitals: true, // 默认开启webVitals收集器

      // 勾选session功能
      session: true,

      // 同时勾选api和trace或者仅勾选trace
      api: {
        // 勾选trace功能
        injectTraceHeader: 'traceparent', // session串联后端
        injectTraceUrls: [/t.tux.qq.com/, /tux.qq.com/], // session串联后端
      },

    },
  });
}

import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import TDesign from 'tdesign-vue';

import '@/styles/index.scss'; // global css

import App from './App.vue';

import router from '@/router';

import { beacon } from '@/utils/beacon';
import VueCompositionAPI from '@vue/composition-api';
// import { flexible } from './utils/font-size-helper';
// import { remFix } from './utils/rem-fix';
// flexible(860);
// remFix(860);

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(TDesign);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

import '@/assets/css/base.less';
import '@/assets/css/td-ext.less';

function getQueryArgs() {
  const hash = window.location.hash.substring(1); // "/tux/NPS?survey_id=134&uuid=xxx"
  const obj = hash.split('?')?.[1]?.split("&")?.reduce(function (prev, curr) {
    var pair = curr.split("=");
    prev[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    return prev;
  }, {}) ?? {};
  return obj;
}

async function getQ36() {
  return new Promise((reslove) => {
    if (window?.browser?.app?.getBrowserParam) {
      window?.browser?.app?.getBrowserParam((params) => {
        const { qimei36 } = params;
        reslove(qimei36);
      });
      return;
    }
    reslove('');
  });
}

console.log('CLINT_ENV:', window.ENV_CONSTANT.CLINT_ENV);

const startTime = performance.now();
window.addEventListener('beforeunload', async function () {
  const endTime = performance.now();
  const time = endTime - startTime;
  const queryArgs = getQueryArgs();
  let survey_type = '';
  const hash = window.location.hash.substring(1)
  if (hash.toLocaleLowerCase().indexOf('csat') > -1) {
    survey_type = 'csat';
  }
  if (hash.toLocaleLowerCase().indexOf('nps') > -1) {
    survey_type = 'nps';
  }
  const qimei36 = await getQ36();
  beacon.onDirectUserAction('tux_h5_logout', {
    env: window.ENV_CONSTANT.CLINT_ENV,
    iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
    survey_id: queryArgs?.survey_id,
    survey_type,
    business: queryArgs?.app_id ?? '',
    user_id: qimei36,
    duration_time: time,
  });
});

window.addEventListener('load', function () {
  let survey_type = '';
  const hash = window.location.hash.substring(1)
  if (hash.toLocaleLowerCase().indexOf('csat') > -1) {
    survey_type = 'csat';
  }
  if (hash.toLocaleLowerCase().indexOf('nps') > -1) {
    survey_type = 'nps';
  }
  var performance = window.performance;
  if (!performance) {
    // 当前浏览器不支持
    console.log('你的浏览器不支持 performance 接口');
    beacon.onDirectUserAction('tux_h5_init', {
      router: window.location.href,
      env: window.ENV_CONSTANT.CLINT_ENV,
      iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
      // @ts-ignore
      tux_uuid: queryArgs?.uuid,
      // @ts-ignore
      survey_id: queryArgs?.survey_id,
      survey_type,
      server_type: 'H5',
      loading_time: '-1',
      app_id: queryArgs?.app_id ?? 'qb',
    })
    return;
  }
  const navEntry = performance.getEntriesByType("navigation")[0];
  // @ts-ignore
  const loading_time = navEntry.domInteractive - navEntry.fetchStart;
  const queryArgs = getQueryArgs();
  beacon.onDirectUserAction('tux_h5_init', {
    router: window.location.href,
    env: window.ENV_CONSTANT.CLINT_ENV,
    iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
    // @ts-ignore
    tux_uuid: queryArgs?.uuid,
    // @ts-ignore
    survey_id: queryArgs?.survey_id,
    survey_type,
    server_type: 'H5',
    loading_time,
    app_id: queryArgs?.app_id ?? 'qb',
  })
});

// import VConsole from 'vconsole';
// const vConsole = new VConsole();

function initWxFont() {
  const handleFontSize = () => {
    console.log('执行 handleFontSize');
    console.log('WeixinJSBridge?.invoke:', WeixinJSBridge?.invoke);
    console.log('WeixinJSBridge?.on:', WeixinJSBridge?.on);
    console.log('document.body.style.fontSize:', document.body.style.fontSize);
    // 在微信网页中字体分为7级(fontSize)，2为标准
    WeixinJSBridge?.invoke?.('setFontSizeCallback', { fontSize: 2 });
    WeixinJSBridge?.on?.('menu:setfont', function () {
      WeixinJSBridge?.invoke?.('setFontSizeCallback', { fontSize: 2 });
    });
  };

  try {
    // 安卓 禁止用户自定义设置字体大小
    // @ts-ignore
    if (typeof WeixinJSBridge == 'object' && typeof WeixinJSBridge?.invoke == 'function') {
      console.log('WeixinJSBridge对象存在');
      handleFontSize();
    } else {
      if (document.addEventListener) {
        console.log('WeixinJSBridge对象不存在，使用document.addEventListener');
        document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
      } else {
        // @ts-ignore
        if (document.attachEvent) {
          console.log('WeixinJSBridge对象不存在，使用document.attachEvent');
          // @ts-ignore
          document.attachEvent('WeixinJSBridgeReady', handleFontSize);
          // @ts-ignore
          document.attachEvent('onWeixinJSBridgeReady', handleFontSize);
        }
      }
    }
  } catch (error) { }
  // 强制设置字体大小
  document.body.style.fontSize = '14px';
}

initWxFont();
