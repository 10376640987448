import axios, { AxiosRequestConfig } from 'axios';

const magicConchService = axios.create({
  baseURL: '/magic-conch', // api 的 base_url
  timeout: 1000000, // request timeout
});

// 发起请求拦截
magicConchService.interceptors.request.use(
  (config) => {
    // const logId = generateUUID();
    const newConfig = {
      ...config,
    };
    return newConfig;
  },
  error => Promise.reject(error),
);

magicConchService.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

magicConchService.defaults.validateStatus = (status) => {
  if (status === 401) {
    const { protocol, host, href } = window.location;
    const url = `${protocol}//${host}/_logout/?url=${encodeURIComponent(href)}`;
    window.location.href = url;
  }
  return true;
};

magicConchService.interceptors.response.use(
  response => response,
  (error) => {
    console.log(error);
  },
);

async function get<Any>(path: string, config?: AxiosRequestConfig): Promise<Any> {
  const result = await magicConchService.get(path, config);
  try {
    return result.data as Any;
  } catch (err) {
    console.log(err);
    return err as any;
  }
}

async function post<Any>(path: string, data: any, config?: AxiosRequestConfig): Promise<Any> {
  const result = await magicConchService.post(path, data, config);
  try {
    return result.data as Any;
  } catch (err) {
    console.log(err);
    return err as any;
  }
}

export const magicConchAxiosHelper = {
  get,
  post,
};

export const magicConchAxios = magicConchService;

export const MAGIC_CONCH_ACTIONS = {
  add: '/api/file/add',
  getList: '/api/file/list',
  getInfo: '/api/file/info',
  delete: '/api/file/delete',
  update: '/api/file/update',
  // {{host}}/api/pangu/role/list/{{projectId}}/{{token}}
  getSysRoles: '/api/pangu/role/list',
  // {{host}}/api/pangu/user/userRole/{{projectId}}/{{token}}/{{user}}
  getUserRoles: '/api/pangu/user/userRole',
  // {{host}}/api/pangu/action/list/{{projectId}}/{{token}}
  getSysActions: '/api/pangu/action/list',
  // {{host}}/api/pangu/user/userAction/{{projectId}}/{{token}}/{{user}}?isTree=1
  getUserActions: '/api/pangu/user/userAction',
  // {{host}}/api/pangu/apply/create/{{projectId}}/{{token}}/{{user}}
  createUserRoles: '/api/pangu/apply/create',
  // {{host}}/api/pangu/getApprovalList/{{projectId}}/{{token}}/{{user}}
  getApprovalList: '/api/pangu/getApprovalList',
};
