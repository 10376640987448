import {
  ESubmitSurveyStatus,
  getPersonalizedLegacy,
  ISubmitSurveyArgs,
  ISubmitSurveyQuestionMartix,
  ISubmitSurveyQuestionOption,
  ISubmitSurveyQuestionOptionText,
  ISubmitSurveyQuestionResult,
  ISubmitSurveyQuestionText,
  submitSurvey,
} from '@/api/survey';
import CusLoading from '@/components/CusLoading';
import { Col, Row } from '@/components/Grid';
import QuestionItem from '@/components/QuestionItem';
import { getDisplayTreeNodesByQuestions, getFlattenTreeNode, getQuestionResItems, IDisplayTreeNode, verifyResQuestions } from '@/components/QuestionItem/util';
import { EQuestionItemType, IQuestionResItem, IQuestionResStar, IQuestionSurveyModel } from '@/interface/survey';
import { parseTime } from '@/utils';
import { beacon } from '@/utils/beacon';
import { useRef } from '@/utils/composition-helper';
import { HtmlHelper } from '@/utils/html-helper';
import { isNumber } from '@/utils/number-ext';
import { convert2SurveyQuestions, getDisplayTreeNodes, hasOptions, onH5SubmitAnswer } from '@/utils/survey-helper';
import { computed, defineComponent, onMounted, watch } from '@vue/composition-api';
import { Message } from 'element-ui';
import _ from 'lodash';
import { MessagePlugin } from 'tdesign-vue';
import { VNode } from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import './index.less';

/**
 * Csat问卷(overflow布局)
 */
export default defineComponent({
  name: 'CsatOverflow',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isSmallDevice = innerWidth < 330;
    const halfDialogArr = ['3', '4', '5'];
    const started_at = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}');
    const [haveSurvey, setHaveSurvey] = useRef(false);
    const [q36Init, setQ36Init] = useRef<boolean>(false);
    const [q36, setQ36] = useRef<string | undefined>();
    const [submitLoading, setSubmitLoading] = useRef(false);
    const [loading, setLoading] = useRef(false);
    const [legacy, setLegacy] = useRef<IQuestionSurveyModel | undefined>();
    // 已选择的题目（map）
    const [answerMaps, setAnswerMaps] = useRef<Record<string, IQuestionResItem>>({});
    const [displayTreeNodes, setDisplayTreeNodes] = useRef<IDisplayTreeNode[]>([
      { id: '1', indexPath: [0], children: [] }
    ]);
    const zone_id = computed(() => {
      return route.query.zone_id as string ?? '';
    });
    const survey_type = computed(() => {
      // CSAT或NPS 转换为小写
      return route.params.survey_type?.toLocaleLowerCase();
    });
    const title = computed(() => {
      const title = legacy.value?.title ?? '';
      if (_.isString(title) && title.length > 0) {
        return title;
      }
      return '用户调研';
    });
    watch(() => title.value, (newVal) => {
      HtmlHelper.setTitle(newVal);
    }, { immediate: true });
    // 显示的题目，默认显示量表题（一维打平）
    const flatDisplayTreeNodes = computed(() => getFlattenTreeNode(displayTreeNodes.value));
    // 根据一维打平结果，最终确定需要提交的问题
    const displays = computed(() => {
      const ids = flatDisplayTreeNodes.value.map(c => Number(c.id));
      const arr = Array.from(new Set(ids)).map(c => c.toString()).filter(c => c !== '-1');
      // 根据问题顺序处理显示问题顺序
      const result = metaQuestions.value.reduce((res: string[], question) => {
        const index = arr.findIndex(c => c === question.id);
        if (index > -1) {
          return [...res, question.id];
        }
        return res;
      }, []);
      return result;
    });
    const uuid = computed(() => {
      const taskinfo_id = route.query.taskinfo_id as string | undefined;
      // 如果有传递task信息的id过来，则使用uuid拼接
      if (taskinfo_id && q36.value) {
        // 补充id为6位
        const left = _.padStart(taskinfo_id, 6, '0');
        // 拼接uuid
        // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
        return `${left}-${q36.value}`;
      }
      return route.query.uuid as string ?? '';
    });
    const personalizedLegacyArgs = computed(() => {
      const { app_id, survey_id } = route.query as Record<string, string | undefined>;
      if (survey_id && q36Init.value) {
        return {
          zone_id: zone_id.value,
          survey_id,
          user_id: q36.value ?? '',
          business: app_id ?? 'qb',
          is_white: route.query.is_white === '1',
          is_permanent: route.query.is_permanent === '1',
        }
      }
      return undefined;
    });
    const position = computed(() => route.query.position);
    // 如果路由中存在option_id参数，则获取对应需要默认勾选第一道量表题，并展示量表题相关displays
    const defaultDisplayTreeNodes = computed(() => {
      const questions = metaQuestions.value ?? [];
      const firstQuestion = questions?.[0];
      const nodes = getDisplayTreeNodesByQuestions(questions);
      const queryOptionId: string | undefined = route.query.option_id as string;
      const nodeIndex = nodes.findIndex(c => c.id === firstQuestion.id && c.children.length === 0);
      const displayNode = nodes[nodeIndex];
      // 如果第一道题是量表题，进行匹配url参数option_id
      if (firstQuestion?.type === EQuestionItemType.Star && isNumber(queryOptionId) && displayNode) {
        const option = firstQuestion.options.find(c => c.id === route.query.option_id);
        const res: IQuestionResStar = {
          type: EQuestionItemType.Star,
          model: _.cloneDeep(firstQuestion),
          value: option ? [_.cloneDeep(option)] : [],
        };
        nodes[nodeIndex].children = option?.displays.map((c, index) => {
          const model: IDisplayTreeNode = {
            id: c,
            indexPath: [...nodes[nodeIndex].indexPath, index],
            children: [],
          };
          return model;
        }) ?? [];
        return {
          displayNodes: nodes,
          questionRes: res,
        };
      }
      return {
        displayNodes: nodes,
        questionRes: undefined,
      };;
    });
    // 如果路由参数option_id匹配了第一道量表题并有默认参数
    watch(defaultDisplayTreeNodes, (newVal, oldVal) => {
      if (newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        const { questionRes, displayNodes } = newVal;
        setDisplayTreeNodes(displayNodes);
        const displayNode = displayNodes?.[0];
        if (questionRes && displayNode) {
          // setAnswerMaps下方的watch metaQuestion初始化时会覆盖，所以这里使用setTimeout宏任务进行延迟设置
          setTimeout(() => {
            const newMaps = _.cloneDeep(answerMaps.value);
            newMaps[displayNode.id] = questionRes;
            setAnswerMaps(newMaps);
          }, 0);
        }
      }
    }, { immediate: true, deep: true });
    const surveyID = computed(() => route.query.survey_id as string ?? '');
    const isHalfDialog = computed(() => halfDialogArr.indexOf(route.query.component_type as string) > -1);
    const fetchFn = async () => {
      if (personalizedLegacyArgs.value) {
        try {
          setLoading(true);
          const startTime = performance.now();
          const res = await getPersonalizedLegacy(personalizedLegacyArgs.value, route.query.app_id as string);
          const endTime = performance.now();
          const elapsedTime = endTime - startTime;
          // 上报请求耗时
          beacon.onDirectUserAction('tux_h5_query_elapsed_time', {
            tux_zone_id: zone_id.value,
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            survey_type: survey_type.value,
            server_type: 'H5',
            elapsed_time: elapsedTime,
            ...personalizedLegacyArgs.value,
            query_res: JSON.stringify(res),
          });
          if (res?.pages.length && res?.pages.length > 0) {
            const pages = res?.pages ?? [];
            const questions = pages?.[0]?.questions ?? [];
            const newQuestions = questions.map(question => {
              // 旧的数据没有required时，默认必填
              if ((question.type === EQuestionItemType.Option || question.type === EQuestionItemType.Star) && question.required === undefined) {
                question.required = true;
              }
              return question;
            });
            res.pages[0].questions = newQuestions;
          }
          setLegacy(res);
          setLoading(false);
          setHaveSurvey(!!res);
        } catch (err) {
          setLegacy(undefined);
          setLoading(false);
        }
      }
    }
    /**
     * 接口中获取的所有问题列表
     */
    const metaQuestions = computed(() => {
      if (legacy.value?.pages[0].questions.length === 0) {
        return [];
      }
      const questions = legacy.value?.pages[0].questions ?? [];
      return convert2SurveyQuestions(questions);
    });
    const displayQuestionEles = computed(() => {
      const eles = displays.value.reduce((res: VNode[], display, index) => {
        const displayNode = flatDisplayTreeNodes.value.find(c => c.id === display);
        const question = answerMaps.value[display];
        if (question && displayNode && legacy.value) {
          const { indexPath } = displayNode;
          return [
            ...res,
            <QuestionItem
              index={index}
              surveyModel={legacy.value}
              answerMaps={answerMaps.value}
              value={question}
              onChange={(val) => {
                const newMaps = _.cloneDeep(answerMaps.value);
                newMaps[display] = val;
                setAnswerMaps(newMaps);
                console.log('change val:', val);
                console.log('change newMaps:', newMaps);
                if (hasOptions(val.model)) {
                  const nodes = getDisplayTreeNodes(displayNode, newMaps);
                  const newArr = _.cloneDeep(displayTreeNodes.value);
                  const path = indexPath.join(',children,').split(',');
                  const newModel = _.cloneDeep(displayNode);
                  newModel.children = nodes[0].children;
                  // 替换所有数组中的元素
                  _.set(newArr, path, newModel);
                  setDisplayTreeNodes(newArr);
                }
              }}
            />,
          ];
        }
        return res;
      }, []);
      return eles;
    });
    watch(personalizedLegacyArgs, (newVal) => {
      if (newVal) {
        fetchFn();
      }
    }, { immediate: true });
    /**
     * 将远程结果全部添加到答题maps中
     */
    watch(() => metaQuestions.value ?? [], (newVal) => {
      const res: IQuestionResItem[] = getQuestionResItems(newVal);
      const maps = _.keyBy(res, function (item) {
        return item.model.id.toString();
      });
      setAnswerMaps(maps);
    }, { immediate: true });
    /**
     * 最终提交的答题结果
     */
    const submitQuestions = computed(() => {
      const res: ISubmitSurveyQuestionResult[] = metaQuestions.value.map(item => {
        const index = displays.value.findIndex(display => display === item.id);
        if (index > -1) {
          const display = displays.value[index];
          const model = answerMaps.value[display];
          if (!model) {
            beacon.onDirectUserAction('tux_h5_submit_error', {
              tux_zone_id: zone_id.value,
              router: window.location.href,
              user_id: 'runhuasu',
              env: window.ENV_CONSTANT.CLINT_ENV,
              iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
              tux_uuid: uuid.value || 'test',
              survey_id: surveyID.value,
              survey_type: survey_type.value,
              server_type: 'H5',
              msg: `选中的问卷和提交参数不匹配，display: ${display},answerMaps:${JSON.stringify(answerMaps)}`,
              app_id: route.query.app_id ?? 'qb',
            });
            throw Error('选中的问卷和提交参数不匹配');
          }
          const { type, value } = model;
          const id = display.toString();
          switch (type) {
            case EQuestionItemType.Star: {
              const model: ISubmitSurveyQuestionText = {
                id,
                type: item.type,
                sub_type: item.sub_type,
                // 量表题提交选中的索引0的text即可
                text: value?.[0]?.text ?? '',
              };
              return model;
            }
            case EQuestionItemType.Option: {
              const options = item.type === EQuestionItemType.Option
                ? item.options.map(c => {
                  const index = value.findIndex(k => c.id === k.id);
                  const status = index > -1 ? ESubmitSurveyStatus.Selected : ESubmitSurveyStatus.Unselected;
                  const model: ISubmitSurveyQuestionOptionText = { id: c.id, text: c.text, status };
                  return model;
                })
                : [];
              const model: ISubmitSurveyQuestionOption = {
                id,
                type: item.type,
                sub_type: item.sub_type,
                options,
              };
              return model;
            }
            case EQuestionItemType.Matrix: {
              const model: ISubmitSurveyQuestionMartix = {
                id,
                type: item.type,
                sub_type: item.sub_type,
                groups: value,
              };
              return model;
            }
            case EQuestionItemType.SensitiveInput:
            case EQuestionItemType.Text: {
              const textAreaModel: ISubmitSurveyQuestionText = {
                id,
                type: item.type,
                sub_type: item.sub_type,
                text: value ?? '',
              };
              return textAreaModel;
            }
            default:
              break;
          }
        }
        // 不存在已选答案的情况，只返回id
        return {
          id: item.id,
        };
      });
      return res;
    });
    /**
     * 展示的结果
     */
    const displayQuestions = computed(() => {
      const res = displays.value.reduce((res: IQuestionResItem[], display) => {
        const model: IQuestionResItem | undefined = answerMaps.value[display];
        if (model) {
          return [...res, model];
        }
        return res;
      }, []);
      return res;
    });
    const progressEle = computed(() => {
      // 必填的总数
      const total = displayQuestions.value.filter(c => c.model.required).length;
      const count = displayQuestions.value.reduce((res: number, item) => {
        if (item.model.required) {
          const { type, value } = item;
          if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
            if (value.length > 0) {
              return res + 1;
            }
          }
          if (type === EQuestionItemType.Text) {
            if (value.length !== 0 && value !== undefined) {
              return res + 1;
            }
          }
        }
        return res;
      }, 0);
      const percentage = (count / total) * 100;
      return <t-progress
        theme="plump"
        percentage={percentage}
        strokeWidth='3px'
        label={() => null}
        trackColor='#C2DAFF'
        color='#0A6CFF'
      />;
    });
    const verifyQuestions = () => {
      const errors = verifyResQuestions(displayQuestions.value);
      return errors;
    };
    const submit = async () => {
      const errors = verifyQuestions();
      if (errors.length > 0) {
        MessagePlugin.error(errors[0]);
        return;
      }
      if (surveyID.value && uuid) {
        const ext_info = route.query.ext_info as string | undefined;
        const query: ISubmitSurveyArgs = {
          survey_id: surveyID.value,
          payload: {
            zone_id: zone_id.value,
            openid: uuid.value as string,
            started_at,
            ended_at: parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
            answer: [
              { questions: submitQuestions.value }
            ],
          },
          is_white: route.query.is_white === '1',
          app_id: route.query.app_id === undefined ? '' : route.query.app_id as string,
          ext_info,
        };
        const params: any = {
          router: window.location.href,
          user_id: 'runhuasu',
          env: window.ENV_CONSTANT.CLINT_ENV,
          iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
          tux_uuid: uuid.value || 'test',
          survey_id: surveyID.value,
          survey_type: survey_type.value,
          server_type: 'H5',
          app_id: route.query.app_id ?? 'qb',
          is_white: route.query.is_white === '1'.toString(),
        };
        if (position.value !== '' && position.value !== undefined) {
          params.position = position.value;
          params.tux_position = position.value;
        }
        beacon.onDirectUserAction('tux_survey_submit', params);
        try {
          setSubmitLoading(true);
          const res = await submitSurvey(query, route.query.app_id as string);
          if (res.data.code === 0) {
            beacon.onDirectUserAction('tux_h5_submit_success', {
              tux_zone_id: zone_id.value,
              router: window.location.href,
              user_id: 'runhuasu',
              env: window.ENV_CONSTANT.CLINT_ENV,
              iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
              tux_uuid: uuid.value || 'test',
              survey_id: surveyID.value,
              survey_type: survey_type.value,
              server_type: 'H5',
              app_id: route.query.app_id ?? 'qb',
              body: JSON.stringify(query),
            });
          } else {
            beacon.onDirectUserAction('tux_h5_submit_error', {
              tux_zone_id: zone_id.value,
              router: window.location.href,
              user_id: 'runhuasu',
              env: window.ENV_CONSTANT.CLINT_ENV,
              iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
              tux_uuid: uuid.value || 'test',
              survey_id: surveyID.value,
              survey_type: survey_type.value,
              server_type: 'H5',
              msg: res.data.msg ?? '',
              app_id: route.query.app_id ?? 'qb',
              body: JSON.stringify(query),
            });
            Message.warning('问卷提交失败')
          }
        } catch (err) {
          beacon.onDirectUserAction('tux_h5_submit_error', {
            tux_zone_id: zone_id.value,
            router: window.location.href,
            user_id: 'runhuasu',
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            tux_uuid: uuid.value || 'test',
            survey_id: surveyID.value,
            survey_type: survey_type.value,
            server_type: 'H5',
            msg: JSON.stringify(err),
            app_id: route.query.app_id ?? 'qb',
            body: JSON.stringify(query),
          });
        }
      } else {
        beacon.onDirectUserAction('tux_h5_submit_error', {
          tux_zone_id: zone_id.value,
          router: window.location.href,
          user_id: 'runhuasu',
          env: window.ENV_CONSTANT.CLINT_ENV,
          iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
          tux_uuid: uuid.value || 'test',
          survey_id: surveyID.value,
          survey_type: survey_type.value,
          server_type: 'H5',
          msg: `缺少 ${surveyID.value ? '' : 'survey_id'} ${uuid ? '' : 'uuid'
            } 参数`,
          app_id: route.query.app_id ?? 'qb',
        });
        MessagePlugin.warning(
          `缺少 ${surveyID.value ? '' : 'survey_id'} ${uuid ? '' : 'uuid'} 参数`
        );
      }
      window.browser?.app?.toast?.('提交成功，感谢你的反馈', 1);
      if (!isHalfDialog.value) {
        window.browser?.app?.historyBack?.();
      }
      onH5SubmitAnswer();
      router.replace({
        name: 'SurveySuccessView',
      });
      setTimeout(() => {
        setSubmitLoading(false);
      }, 1000);
    };
    onMounted(() => {
      if (window?.browser?.app?.getBrowserParam) {
        window?.browser?.app?.getBrowserParam?.((pararms) => {
          const { qimei36 } = pararms;
          setQ36(qimei36);
          setQ36Init(true);
        }, (error) => {
          setQ36Init(true);
          beacon.onDirectUserAction('tux_h5_error_log', {
            tux_zone_id: zone_id.value,
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            tux_uuid: uuid.value || 'test',
            survey_id: surveyID.value,
            survey_type: survey_type.value,
            server_type: 'H5',
            msg: `从 getBrowserParam 获取q36失败：${error}`,
            app_id: route.query.app_id ?? 'qb',
          });
        });
        return;
      }
      setQ36(route.query.q36 as string);
      setQ36Init(true);
    });
    return {
      progressEle,
      submitLoading,
      loading,
      haveSurvey,
      legacy,
      isSmallDevice,
      metaQuestions,
      answerMaps,
      displayTreeNodes,
      flatDisplayTreeNodes,
      displays,
      displayQuestionEles,
      submitQuestions,
      submit,
    };
  },
  render() {
    if (this.loading) {
      return <div style='height: calc(100vh);width: calc(100vw);'>
        <Row class='w-100-p h-100-p' justify='center' align='middle' gutter={8}>
          <Col>
            <CusLoading />
          </Col>
          <Col class='c-pri-6 fs-16px fw-5'>
            加载问卷内容中...
          </Col>
        </Row>
      </div>;
    }
    if (!this.haveSurvey) {
      return <div class="container">
        <div style="text-align: center">
          <img
            src={require('@/assets/error.png')}
            width="196"
            height="228"
            style="margin-top: 194px"
          />
          <div style="color: #8f8f8f; font-size: 16px; line-height: 20px">
            网络出错，请稍后重试
          </div>
        </div>
      </div>;
    }
    return <div class='csat-overflow-survey-panel flex-columns' style="height: calc(100vh)">
      <div class='flex-none'>
        <Row class='h-10px w-100-p ' align='middle' style={{ background: '#F0F6FF' }}>
          <Col span={12} class='pl-24px pr-24px'>
            {this.progressEle}
          </Col>
        </Row>
      </div>
      <div class='flex-1 pl-24px pr-24px pt-16px' style={{ overflowY: 'auto' }}>
        {this.displayQuestionEles}
      </div>
      <div class='flex-none pl-24px pr-24px pt-16px pb-48px'>
        <div
          class="submit"
          onClick={() => {
            this.submit();
          }}
        >
          <Row align='middle' justify='center' gutter={16}>
            {
              this.submitLoading
                ? [
                  <Col><t-icon name='loading' /></Col>,
                  <Col>提交中</Col>
                ]
                : <Col>提交</Col>
            }
          </Row>
        </div>
      </div>
    </div>;
  },
});
