import { IResResultMap, IResResultT } from '@/interface/common';
import { EQuestionItemSubType, EQuestionItemType, IQuestionItem, IQuestionMatrixResRow, IQuestionMatrixStyle, IQuestionOptionStyle, IQuestionStarStyle, IQuestionSurveyModel } from '@/interface/survey';
import servies from '@/utils/request';
import _ from 'lodash';

export interface IQuerySurveyRes {
  title: string;
  create_time: string;
  update_time: string;
  last_modified_by: string;
  index_group_id: string;
  project_id: string;
  pages: [
    {
      id: string;
      questions: IQuestionItem[];
    }
  ];
}

export interface IGetPersonalizedLegacyArgs {
  survey_id: string | number;
  user_id: string;
  business: string;
  is_white: boolean;
  is_permanent: boolean
}

/**
 * 问卷来源
 */
export enum ELegacySource {
  Native = 0,
  H5 = 1,
}

/**
 * 删除问题中关于量表题、选择题的无效displays(比如第二题的displays包含第一题的id这类无效展示逻辑)
 * @param questions 
 */
export function deleteInvalidDisplays(questions: IQuestionItem[]): IQuestionItem[] {
  // 所有未被软删除的问题
  const questionDisplayIds = questions.filter(c => c.is_deleted !== true).map(c => c.id);
  const result = questions.map(question => {
    // 矩阵题和选择题的情况下需要校验它们的option.displays是否合规
    // 不允许绑定当前题目之前的题目（比如当前题目是第4道题，而里面绑定展示了第2道题，不允许这种情况发生）
    if (question.type === EQuestionItemType.Star || question.type === EQuestionItemType.Option) {
      const currQuestionIndex = questions.findIndex(c => question.id === c.id);
      const options = question.options.map(option => {
        const oldDisplays = option.displays ?? [];
        const displays = oldDisplays.reduce((res: string[], displayId) => {
          const displayQuestionIndex = questions.findIndex(c => c.id === displayId);
          // 符合规则时
          if (currQuestionIndex < displayQuestionIndex) {
            return [...res, displayId];
          }
          return res;
        }, []);
        // 根据未被删除的问题顺序，校验option的display，剔除已被删除的问题id
        const newDisplays = filterOptionDisplays(questionDisplayIds, displays);
        return { ...option, displays: newDisplays };
      });
      return { ...question, options };
    }
    return question;
  });
  return result;
}

/**
 * 根据需要展示的问题ids，过滤option的displays（剔除问题已被软删除的id&按照问题顺序排列displays）
 * @param questionDisplayIds 需要显示的问题ids（未被软删除的问题ids）
 * @param displayIds option的displays
 * @returns
 */
export function filterOptionDisplays(questionDisplayIds: string[], displayIds: string[]) {
  // 根据未被删除的问题顺序，校验option的display，剔除已被删除的问题id
  const displays = questionDisplayIds.reduce((res: string[], questionDisplayId) => {
    const index = displayIds.findIndex(id => id === questionDisplayId);
    // 如果存在，则抛出
    if (index > -1) {
      return [...res, questionDisplayId];
    }
    return res;
  }, []);
  return displays;
}

/**
 * 初始化问卷问题（比如对没有sub_type的问题进行填入默认值）
 * @param questions
 */
export function initializeQuestions(questions: IQuestionItem[]): IQuestionItem[] {
  const newQuestions = deleteInvalidDisplays(questions);
  return newQuestions.map((question) => {
    const { type, sub_type } = question;
    // 没有sub_type的情况，进行默认填充
    if (sub_type === undefined || sub_type.length === 0) {
      switch (type) {
        case EQuestionItemType.Star: {
          const styleObj: IQuestionStarStyle = question.style ? JSON.parse(question.style.replace(/: "/g, ':"')) : {
            star_question_render_style: 'emoji',
          };
          const sub_type = styleObj.star_question_render_style === 'text'
            ? EQuestionItemSubType.STAR_TEXT
            : EQuestionItemSubType.STAR_STAR;
          return { ...question, sub_type };
        }
        case EQuestionItemType.Option: {
          const styleObj: IQuestionOptionStyle = question.style ? JSON.parse(question.style.replace(/: "/g, ':"')) : {
            line_items: '3',
          };
          const sub_type = styleObj.max === '1'
            ? EQuestionItemSubType.RADIO
            : EQuestionItemSubType.CHECKBOX;
          return { ...question, sub_type };
        }
        case EQuestionItemType.Matrix: {
          const styleObj: IQuestionMatrixStyle = question.style
            ? JSON.parse(question.style.replace(/: "/g, ':"'))
            : {
              display_style: 'matrix',
            };
          const sub_type = styleObj.max === '1'
            ? EQuestionItemSubType.MATRIX_RADIO
            : EQuestionItemSubType.MATRIX_CHECKBOX;
          return { ...question, sub_type };
        }
        case EQuestionItemType.Text: {
          const sub_type = EQuestionItemSubType.TEXTAREA;
          return { ...question, sub_type };
        }
        case EQuestionItemType.SensitiveInput: {
          const sub_type = EQuestionItemSubType.SENSITIVE_INPUT;
          return { ...question, sub_type };
        }
        default:
          return question;
      }
    }
    return question;
  });
}

// 查询端内问卷
export async function getPersonalizedLegacy(args: IGetPersonalizedLegacyArgs, app_id: string) {
  const url = app_id ? `/surveys/${app_id}/inapp` : '/surveys/inapp';
  const res = await servies.post<IResResultMap<{ legacy: IQuestionSurveyModel }>>(url, {
    ...args,
    source: ELegacySource.H5,
  });
  if (res.data.code === 0 && _.isPlainObject(res.data.legacy)) {
    const legacy = res.data.legacy;
    if (legacy.pages?.[0].questions) {
      legacy.pages[0].questions = initializeQuestions(legacy.pages?.[0].questions);
      return legacy;
    }
    return res.data.legacy;
  }
  return undefined;
}

// 查询端内问卷
export async function querySurvey(survey_id: string | number) {
  const res = await servies.get<IResResultT<IQuestionSurveyModel>>('/survey', {
    params: {
      survey_id,
    }
  });
  if (res.data.code === 0 && _.isPlainObject(res.data.data)) {
    return res.data.data;
  }
  return undefined;
}

export enum ESubmitSurveyStatus {
  Selected = 0,
  /**
   * 未勾选option需要标记为1
   * 提交问卷时回传给后端 https://tapd.woa.com/10046451/prong/stories/view/1010046451887897937
   */
  Unselected = 1,
}

/**
 * 问卷postMessage回调
 */
export enum ESurveyCallbackFn {
  SUCCESS = 'success',
  CANCEL = 'cancel',
}

export interface ISubmitSuveryQuestionDefault {
  id: string;
}

export interface ISubmitSuveryQuestionBase {
  id: string;
  type: EQuestionItemType;
  sub_type: EQuestionItemSubType;
}

export interface ISubmitSurveyQuestionText extends ISubmitSuveryQuestionBase {
  text: string;
}

export interface ISubmitSurveyQuestionOptionText {
  id: string;
  text: string;
  // 没有勾选的情况，status为1
  status: ESubmitSurveyStatus;
}

export interface ISubmitSurveyQuestionOption extends ISubmitSuveryQuestionBase {
  options: ISubmitSurveyQuestionOptionText[];
}

export interface ISubmitSurveyQuestionMartix extends ISubmitSuveryQuestionBase {
  groups: IQuestionMatrixResRow[];
}

export type ISubmitSurveyQuestionResult = ISubmitSuveryQuestionDefault | ISubmitSurveyQuestionText | ISubmitSurveyQuestionOption | ISubmitSurveyQuestionMartix;

export interface ISubmitSurveyAnswer {
  questions: ISubmitSurveyQuestionResult[];
}

export interface ISubmitSurveyArgs {
  survey_id: string;
  payload: {
    zone_id: string;
    openid: string;
    started_at: string;
    ended_at: string;
    answer: ISubmitSurveyAnswer[];
  };
  is_white: boolean;
  app_id: string;
  ext_info?: string;
}

/**
 * 提交问卷
 * @param args 
 * @returns 
 */
export async function submitSurvey(args: ISubmitSurveyArgs, app_id: string) {
  const url = app_id ? `/surveys/${app_id}/upload` : '/surveys/upload';
  const res = await servies.post<IResResultT<any>>(url, args);
  return res;
}
