import { defineComponent, PropType } from '@vue/composition-api';
import _ from 'lodash';
import { } from 'vue';
import './index.less';

/**
 * Row组件
 */
export default defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Row',
  props: {
    className: {
      type: String,
      default: '',
    },
    align: {
      type: String as PropType<'top' | 'middle' | 'bottom' | 'stretch'>,
      default: 'top',
    },
    justify: {
      type: String as PropType<'start' | 'end' | 'center' | 'space-around' | 'space-between'>,
      default: 'start',
    },
    tag: {
      type: String,
      default: 'div',
    },
    gutter: [Number, Array],
  },
  setup() {
    return {
    };
  },
  render() {
    const { align, gutter } = this.$props;
    const gutterIsArr = gutter && _.isArray(gutter);
    const rowGap = gutterIsArr ? gutter[1] as number : undefined;
    const currStyle: Record<string, string> = {};
    // ios14.4及以前的safari浏览器不兼容row-gap，父元素使用负margin，子元素使用margin的方式实现行之间的间距
    const isGutterClass = rowGap !== undefined ? `cus-row-gutter-${rowGap / 2}px` : '';
    if (align === 'stretch') {
      const props = _.omit(this.$props, 'align');
      return <t-row
        props={props}
        class={`cus-row-align-items-stretch ${isGutterClass} ${this.$props.className}`}
        style={currStyle}
      >{this.$slots.default}</t-row>;
    }
    return <t-row
      props={this.$props}
      class={`${isGutterClass} ${this.$props.className}`}
      style={currStyle}
    >{this.$slots.default}</t-row>;
  },
});
