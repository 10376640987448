import { defineComponent } from '@vue/composition-api';
import './index.less';

/**
 * Radio选择组件
 */
export default defineComponent({
  name: 'CusLoading',
  props: {
  },
  setup() {
    return {
    };
  },
  render() {
    return <div class='cus-loading'></div>;
  },
});
