import _ from 'lodash';

/**
 * 判断是否数字
 * @param value
 */
export function isNumber(value?: string | number | null) {
  if (value === '' || value === null) {
    return false;
  }
  const num = Number(value);
  if (num.toString() === 'NaN') {
    return false;
  }
  return _.isNumber(num);
}
