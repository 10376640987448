/**
 * Created by jiachenpan on 16/11/18.
 */
import * as  XLSX  from 'xlsx';

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if ((`${time}`).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = `0${value}`;
    }
    return value || 0;
  });
  return timeStr;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } if (diff < 3600) {
    // less 1 hour
    return `${Math.ceil(diff / 60)}分钟前`;
  } if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`;
  } if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  }
  return (`${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`);
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 *get getByteLen
 * @param {String} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/gi) != null) {
      len += 1;
    } else {
      len += 0.5;
    }
  }
  return Math.floor(len);
}

export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map((key) => {
    if (json[key] === undefined) return '';
    return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
  })).join('&');
}

export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(`{"${decodeURIComponent(search).replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')}"}`);
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

export function scrollTo(element, to, duration) {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;
  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += `${className}`;
  } else {
    classString = classString.substr(0, nameIndex)
      + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  },
];

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  }
  return new Date(new Date().toDateString());
}

export function debounce(func, wait, immediate) {
  let timeout; let args; let context; let timestamp; let result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) {
          context = null;
          args = null;
        }
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = null;
      args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * This is a function which can sort a series of Object by property(Only string property now)
 * Welcome to complement it
 * @author Runhuasu
 * @param {*} propName name of property
 * @param {*} arr sorted array
 * @param {*} isAscending ascending for true,decending for false
 */
export function sortObjectByStringProperty(propName, arr, isAscending) {
  const newArr = deepClone(arr);
  return newArr.sort(compare(propName));
  function compare(propName) {
    return function (obj1, obj2) {
      if (typeof obj1[propName] === 'undefined') return isAscending ? -1 : 1;
      if (typeof obj2[propName] === 'undefined') return isAscending ? 1 : -1;
      const value1 = obj1[propName];
      const value2 = obj2[propName];
      if (value1 > value2) return isAscending ? 1 : -1;
      if (value1 === value2) return 0;
      return isAscending ? -1 : 1;
    };
  }
}
/**
 *
 */
/**
 * This is a function which can sort a series of Object by property(Only Number property now)
 * Welcome to complement it
 * @author Runhuasu
 * @param {*} propName name of property
 * @param {*} arr sorted array
 * @param {*} isAscending ascending for true,decending for false
 */
export function sortObjectByNumberProperty(propName, arr, isAscending) {
  const newArr = deepClone(arr);
  return newArr.sort(compare(propName));
  function compare(propName) {
    return function (obj1, obj2) {
      if (typeof obj1[propName] === 'undefined') return isAscending ? -1 : 1;
      if (typeof obj2[propName] === 'undefined') return isAscending ? 1 : -1;
      const value1 = parseFloat(obj1[propName]);
      const value2 = parseFloat(obj2[propName]);
      if (value1 > value2) return isAscending ? 1 : -1;
      if (value1 === value2) return 0;
      return isAscending ? -1 : 1;
    };
  }
}
/**
 *
 */
export function getTimeBeforeNDays(n) {
  const now = new Date();
  const date = new Date(now.getTime() - (n * 24 * 3600 * 1000));
  const year = date.getFullYear();
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  const hour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
  const min = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  const sec = date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
  return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
}
/**
 *  Parse all properties of a object to ''
 */
export function cleanObj(obj) {
  const newObj = deepClone(obj);
  for (const key in newObj) {
    newObj[key] = '';
  }
  return newObj;
}

export function generateTableData(originData, classifyName, tableColumn, transParamsList) {
  if (!(originData instanceof Array)) return [];
  const classList = Array.from(new Set(originData.map(item => item[classifyName])));
  const classifiedData = classList.map(item => originData.filter(data => data[classifyName] === item));
  const result = classList.map((item, index) => {
    const obj = {};
    obj[classifyName] = item;
    for (const data of classifiedData[index]) {
      obj[data[tableColumn]] = {
        ...data,
      };
      if (transParamsList instanceof Array) {
        transParamsList.forEach((param) => {
          obj[param] = data[param];
        });
      }
    }
    return obj;
  });
  return result;
}

export function generateOrderTableData(
  originData, classifyName, tableColumn, transParamsList, indexNameOrderList,
  currentProduct,
) {
  if (indexNameOrderList == null) {
    return [];
  }
  if (!(originData instanceof Array) || originData.length === 0) return [];
  const classList = Array.from(new Set(originData.map(item => item[classifyName])));
  const classifiedData = classList.map(item => originData.filter(data => data[classifyName] === item));
  const result = classList.map((item, index) => {
    const obj = {};
    obj[classifyName] = item;
    for (const data of classifiedData[index]) {
      obj[data[tableColumn]] = {
        ...data,
      };
      if (transParamsList instanceof Array && data[tableColumn] === currentProduct) { // 临时这么写，
        transParamsList.forEach((param) => {
          obj[param] = data[param];
        });
      }
    }
    return obj;
  });
  const resultIndexNameMap = {};
  for (const data of result) {
    if (data.hasOwnProperty('index_name')) {
      resultIndexNameMap[data.index_name] = data;
    }
  }
  const resultOrderByIndexName = [];
  for (const indexName of indexNameOrderList) {
    if (resultIndexNameMap.hasOwnProperty(indexName)) {
      resultOrderByIndexName.push(resultIndexNameMap[indexName]);
    }
  }
  return resultOrderByIndexName;
}

export function objectDuplicateRemoval(arr, prop) {
  const result = [];
  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    if (!obj[arr[i][prop]]) {
      result.push(arr[i]);
      obj[arr[i][prop]] = true;
    }
  }
  return result;
}

export function jumpToBkLogin() {
  // window.location = `http://login.o.oa.com/plain/?size=big&bk_login=1&default_mode=woa&app_code=aipipeline-web&c_url=${window.location.href}`;
}

export function generateUUID() {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
  return uuid;
}

export function findKey(obj, value, compare = (a, b) => a === b) {
  return Object.keys(obj).find(k => compare(obj[k], value));
}

export const mapProfileName = {
  性别: 'gender',
  年龄: 'age',
  地域: 'city_level',
  新老用户: 'is_old',
  收入: 'income',
  活跃度: 'activity',
  职业: 'profession',
  省份: 'province',
  城市: 'city',
  学历: 'education',
  手机品牌: 'phone_brand',
  手机机型: 'phone_model',
  手机版本: 'phone_version',
  日使用时长: 'daily_use_time',
  周使用时长: 'weekly_use_time',
  接触产品时间: 'net_time',
};

export function translateProfile(arrayProfile) {
  if (!(arrayProfile instanceof Array) || arrayProfile.length === 0) return {};

  const result = {};
  arrayProfile.forEach((item) => {
    result[mapProfileName[item.name]] = (() => {
      const result1 = {};
      item.profileList.forEach((item1) => {
        result1[item1.key] = item1.value;
      });
      return result1;
    })();
  });
  return result;
}

export function reTranslateProfile(objProfile) {
  if (!(objProfile instanceof Object) || objProfile instanceof Array) return {};

  const result = [];
  for (const k in objProfile) {
    result.push({
      name: findKey(mapProfileName, k),
      profileList: (() => {
        const result1 = [];
        for (const m in objProfile[k]) {
          result1.push({
            key: m,
            value: objProfile[k][m],
          });
        }
        return result1;
      })(),
    });
  }
  return result;
}

export function arrHaveEmpty(arr) {
  let result = false;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === undefined) {
      result = true;
      break;
    }
  }
  return result;
}

export function b64ToUtf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function handleWjTitleHTML(str) {
  if (!str) return '';
  if (str.indexOf('&gt;') < 0) {
    return str;
  }
  let result = '';
  try {
    result = str.split('&gt;')[1].split('&lt;')[0];
  } catch (error) {
    console.log('error:', error);
  }
  return result;
}

export function generateExcel(data, title) {
  // 创建book
  const wb = XLSX.utils.book_new();
  // json转sheet
  const ws = XLSX.utils.json_to_sheet(data);
  // 设置列宽
  if (data.lenth > 0) {
    ws['!cols'] = [
      { width: 30 },
      { width: 60 },
    ];
  }
  // sheet写入book
  XLSX.utils.book_append_sheet(wb, ws, 'file');
  // 输出
  XLSX.writeFile(wb, title);
}

// HTML字符转义
export function stringValFilter(str) {
  let s = '';
  if (str.length === 0) {
    return '';
  }
  s = str.replace(/&amp;/g, '&');
  s = s.replace(/&lt;/g, '<');
  s = s.replace(/&gt;/g, '>');
  s = s.replace(/&nbsp;/g, ' ');
  s = s.replace(/&#39;/g, '\'');
  s = s.replace(/&quot;/g, '"');
  return s;
}

// 移除HTML标签
export function removeHTMLTag(str) {
  str = str.replace(/<\/?[^>]*>/g, ''); // 去除HTML tag
  str = str.replace(/[ | ]*\n/g, '\n'); // 去除行尾空白
  // str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
  str = str.replace(/ /ig, '');// 去掉
  return str;
}

export function isJSON(str) {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj !== null) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  } else return false;
}

// 判断当前浏览器类型，根据浏览器版本演变可能需要调整
export function typeOfBroswer() {
  const broswer = window.navigator.userAgent;

  const haveEdge = broswer.indexOf('Edg') > -1;
  const haveChrome = broswer.indexOf('Chrome') > -1;
  const haveFirefox = broswer.indexOf('Firefox') > -1;
  const haveSafari = broswer.indexOf('Safari') > -1;

  if (haveChrome && haveSafari && !haveEdge) return 'chrome';
  if (haveEdge) return 'edge';
  if (!haveChrome && haveSafari) return 'safari';
  if (haveFirefox) return 'firefox';
}

// 修改一个对象中的某些key，value不变
// 如传入obj:     { id: '1', name: 'haha'}，
// 传入keyMap:   { id: '序号',name:'名字'}，
// 最终返回      { 序号:'1', 名字:  'haha'}
export function changeObjKeys(obj, keyMap) {
  const result = Object.keys(obj).reduce((newObj, key) => {
    const newKey = keyMap[key] || key;
    newObj[newKey] = obj[key];
    return newObj;
  }, {});
  return result;
}

// 将一个数组打乱顺序，随机排序
export function randomSortArr(arr) {
  if (!arr instanceof Array) return arr;

  return arr.sort(() => Math.random() - 0.5);
}
