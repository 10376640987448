import { Col, Row } from '@/components/Grid';
import { IValidateResult } from '@/interface/form-helper';
import { EQuestionItemSubType, EQuestionItemType, EQuestionMatrixDisplayStyle, EQuestionOptionStatus, IQuestionMatrixResRow, IQuestionMatrixStyle, IQuestionOptionItem, IQuestionResItem, IQuestionResMatrix, IQuestionSubTitle, IQuestionSurveyModel } from '@/interface/survey';
import { useDebounceFn, useRef } from '@/utils/composition-helper';
import { isNumber } from '@/utils/number-ext';
import { PropType, computed, defineComponent, toRefs, watch } from '@vue/composition-api';
import _ from 'lodash';
import { VNode } from 'vue';
import { getTitleByQuestionItem } from '..';
import CusCheckbox from '../CusCheckbox';
import CusRadio from '../CusRadio';
import { getMaxTipsEle } from '../util';
import './index.less';

/**
 * 问卷问题-矩阵题
 */
export default defineComponent({
  name: 'QuestionMatrix',
  props: {
    disabled: Boolean,
    index: {
      type: Number,
      required: true,
    },
    onChange: Function as PropType<(value: IQuestionResMatrix, oldValue: IQuestionResMatrix) => void>,
    value: {
      type: Object as PropType<IQuestionResMatrix>,
      required: true,
    },
    surveyModel: {
      type: Object as PropType<IQuestionSurveyModel>,
      required: true,
    },
    answerMaps: {
      type: Object as PropType<Record<string, IQuestionResItem>>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { value, index, surveyModel, answerMaps } = toRefs(props);
    const [showError, setShowError] = useRef(false);
    const [timer, setTimer] = useRef();
    const docId = computed(() => `option-max-${index.value}`);
    const refer = computed(() => value.value.model.refer);
    const questions = computed(() => {
      // 找出所有未被删除的题目
      const arr = surveyModel.value.pages?.[0]?.questions?.filter(c => c.is_deleted !== true) ?? [];
      return arr;
    });
    const referIndex = computed(() => {
      const index = questions.value.findIndex(c => c.id === refer.value);
      return index;
    });
    // 引用的问题答题结果对象
    const referAnswer = computed(() => {
      const referAnswer = answerMaps.value[refer.value ?? ''];
      return referAnswer as IQuestionResItem | undefined;
    });
    watch(() => referAnswer.value, (newVal) => {
      // 如果存在引用了前面的多选题时
      if (newVal && newVal.type === EQuestionItemType.Option) {
        const options = newVal.value ?? [];
        const rows = value.value.value ?? [];
        const newMatrixRows: IQuestionMatrixResRow[] = rows.map(row => {
          // 如果管理上一题选项已经不显示了，则把当前矩阵题中匹配的所有选项设置为未选择
          const exist = options.findIndex(option => option.text === row.text) > -1;
          if (!exist) {
            const options = row.options.map(option => ({ ...option, status: EQuestionOptionStatus.UNCHOOSED }));
            return { ...row, options };
          }
          return { ...row };
        });
        const res = _.cloneDeep(value.value);
        res.value = newMatrixRows;
        if (JSON.stringify(res) !== JSON.stringify(value.value)) {
          ctx.emit('change', res, value.value);
        }
      }
    }, { immediate: true, deep: true });
    // 引用其他题之后，但是尚为勾选其他题的情况，进行提示
    const referEmptyEle = computed(() => {
      if (referAnswer.value?.type === EQuestionItemType.Option) {
        const options = referAnswer.value.value ?? [];
        if (options.length === 0) {
          return <div class='pl-18px pr-18px pt-20px pb-20px'>此题选项来源于第{referIndex.value + 1}题中的选项，请先填写第{referIndex.value + 1}题</div>;
        }
      }
      return null;
    });
    const subTitles = computed(() => {
      const sub_titles = value.value.model?.sub_titles ?? [];
      // 存在引用的题目对象
      if (referAnswer.value?.type === EQuestionItemType.Option) {
        const selectedOptions = referAnswer.value.value;
        // 先根据原始结构进行排序，并找出已经被勾选的选项
        const options = referAnswer.value.model.options.reduce((res: IQuestionOptionItem[], item) => {
          // 屎山雕花：因为矩阵题的id是自增的，父问题的option.id也是自增，所以不能使用id进行匹配，只能约束多选题文本不能一样，并使用text匹配
          const index = selectedOptions.findIndex(k => k.text === item.text);
          if (index > -1) {
            return [...res, item];
          }
          return res;
        }, []);
        return options.reduce((res: IQuestionSubTitle[], option) => {
          const model = sub_titles.find(k => k.text === option.text);
          // 如果当前标题已经在引用题中被勾选，则展示
          if (model) {
            return [...res, model];
          }
          return res;
        }, []);
      }
      return sub_titles;
    });
    // 跳转至错误的题目处
    const go2ErroHtmlrElement = useDebounceFn(() => {
      clearTimeout(timer.value as any);
      setShowError(true);
      setTimeout(() => {
        document.querySelector(`#${docId.value}`)?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }, 50);
      setTimer(setTimeout(() => {
        setShowError(false);
      }, 2000));
    }, 10);
    const subTypeTitleEle = computed(() => {
      if (value.value.model.sub_type === EQuestionItemSubType.MATRIX_RADIO) {
        return <span class='multiple-tag fs-14px'>单选</span>;
      }
      return <span class='multiple-tag fs-14px'>多选</span>;
    });
    const ele = computed(() => {
      const item = value.value;
      if (item.type !== EQuestionItemType.Matrix) {
        return null;
      }
      const { style, options, sub_type } = item.model;
      const styleObj: IQuestionMatrixStyle = style
        ? JSON.parse(style.replace(/: "/g, ':"'))
        : {
          display_style: 'matrix',
        };
      const { display_style, max, line_items } = styleObj;
      if (display_style === EQuestionMatrixDisplayStyle.MATRIX) {
        const optionTitleEles = options.map((option) => {
          const { text } = option;
          return <div class='grid-item style-matrix'>
            <Row justify='center' align='middle' class='option-title'>
              <Col>
                {text}
              </Col>
            </Row>
          </div>;
        });
        const rowEles = subTitles.value
          .reduce((res: VNode[], sub_title) => {
            const { text } = sub_title;
            const subTitleEle = <div class='grid-item full-row'>
              <Row align='middle' gutter={8}>
                <Col>
                  {text}
                </Col>
              </Row>
            </div>;
            const optionEles = options.map((option) => {
              const { id } = option;
              const rowIndex = item.value.findIndex((k) => k.id === sub_title.id);
              const colIndex = item.value[rowIndex]?.options?.findIndex?.(option => option.id === id) ?? -1;
              const currResOption = item.value[rowIndex]?.options?.[colIndex];
              const selected = currResOption?.status === EQuestionOptionStatus.CHOOSED;
              if (sub_type === EQuestionItemSubType.MATRIX_RADIO) {
                return <div class='grid-item'>
                  <Row align='middle' justify='center'>
                    <Col>
                      <CusRadio
                        checked={selected}
                        onChange={() => {
                          const res = _.cloneDeep(item);
                          const newMatrixRows = res.value;
                          if (newMatrixRows?.[rowIndex]?.options?.[colIndex]) {
                            // 如果是单选的情况，逻辑改为点击切换（将当前行所有选项设为未选状态）
                            if (sub_type === EQuestionItemSubType.MATRIX_RADIO) {
                              newMatrixRows[rowIndex].options.forEach((colItem, colIndex) => {
                                if (newMatrixRows[rowIndex].options[colIndex].status !== EQuestionOptionStatus.UNSHOWED) {
                                  newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED;
                                }
                              });
                            }
                            const newSelected = !selected;
                            newMatrixRows[rowIndex].options[colIndex].status = newSelected
                              ? EQuestionOptionStatus.CHOOSED
                              : EQuestionOptionStatus.UNCHOOSED;
                            ctx.emit('change', res, item);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </div>;
              }
              return <div class='grid-item'>
                <Row align='middle' justify='center'>
                  <Col>
                    <CusCheckbox
                      checked={selected}
                      onClick={() => {
                        const res = _.cloneDeep(item);
                        const newMatrixRows = res.value;
                        if (newMatrixRows?.[rowIndex]?.options?.[colIndex]) {
                          // （反选之后）如果有最大可选，并且个数和已选个数相等时，弹出错误提示
                          if (!selected && max !== undefined && isNumber(max)) {
                            const options = newMatrixRows[rowIndex].options.filter(option => option.status === EQuestionOptionStatus.CHOOSED);
                            if (Number(max) <= options.length) {
                              go2ErroHtmlrElement();
                              return;
                            }
                          }
                          if (selected) {
                            newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED
                          } else {
                            if (res.model.options[colIndex].exclusive) {
                              newMatrixRows[rowIndex].options.forEach(item => {
                                item.status = EQuestionOptionStatus.UNCHOOSED
                              })
                              newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.CHOOSED
                            } else {
                              newMatrixRows[rowIndex].options.forEach((item, i) => {
                                if (res.model.options[i].exclusive) {
                                  item.status = EQuestionOptionStatus.UNCHOOSED
                                }
                              })
                              newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.CHOOSED
                            }
                          }
                          // const newSelected = !selected;
                          // newMatrixRows[rowIndex].options[colIndex].status = newSelected
                          //   ? EQuestionOptionStatus.CHOOSED
                          //   : EQuestionOptionStatus.UNCHOOSED;
                          ctx.emit('change', res, item);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>;
            });
            const className = `grid-container w-100-p grid-fr-${options.length}`;
            const itemEle = <div class={className}>
              {subTitleEle}
              {...optionEles}
            </div>;
            return [...res, itemEle];
          }, []);
        const className = `grid-container w-100-p grid-fr-${options.length}`;
        return <div class='task-question-item' id={docId.value}>
          {
            index.value !== 0
              ? <div class="divider"></div>
              : null
          }
          <div class='title'>
            <Row gutter={8}>
              <Col class='fs-16px fw-5'>{getTitleByQuestionItem(item.model, index.value)}</Col>
              <Col>{subTypeTitleEle.value}</Col>
              {
                max !== undefined && showError.value
                  ? <Col><span class='error-max-tag cus-shake fs-14px'>每行最多选{max}项</span></Col>
                  : null
              }
            </Row>
          </div>
          {/* 单选非必填情况则不需要显示tips */}
          {
            sub_type === EQuestionItemSubType.MATRIX_RADIO && !item.model.required
              ? null
              : getMaxTipsEle(max, item.model.required, '每行')
          }
          {
            referEmptyEle.value
              ? referEmptyEle.value
              : <div class={className}>
                <div class='full-row'>
                  <div class={className}>
                    {optionTitleEles}
                  </div>
                </div>
                <div class='full-row'>
                  <div class='w-100-p'>
                    {rowEles}
                  </div>
                </div>
              </div>
          }
        </div>;
      }
      const rowEles = subTitles.value
        .reduce((res: VNode[], sub_title) => {
          const { text } = sub_title;
          const subTitleEle = <Row align='middle' gutter={8} class='mt-12px'>
            <Col>
              {text}
            </Col>
          </Row>;
          const optionEles = options.map((option) => {
            const { id, text } = option;
            const rowIndex = item.value.findIndex((k) => k.id === sub_title.id);
            const colIndex = item.value[rowIndex]?.options?.findIndex?.(option => option.id === id) ?? -1;
            const currResOption = item.value[rowIndex]?.options?.[colIndex];
            const selected = currResOption?.status === EQuestionOptionStatus.CHOOSED;
            const columnCount = isNumber(line_items) ? Number(line_items) : 3;
            const span = 12 / columnCount;
            return <Col span={span}
              onClick={() => {
                const res = _.cloneDeep(item);
                const newMatrixRows = res.value;
                if (newMatrixRows?.[rowIndex]?.options?.[colIndex]) {
                  // （反选之后）如果有最大可选，并且个数和已选个数相等时，弹出错误提示
                  if (sub_type === EQuestionItemSubType.MATRIX_CHECKBOX && !selected && max !== undefined && isNumber(max)) {
                    const options = newMatrixRows[rowIndex].options.filter(option => option.status === EQuestionOptionStatus.CHOOSED);
                    if (Number(max) <= options.length) {
                      go2ErroHtmlrElement();
                      return;
                    }
                  }
                  // 如果是单选的情况，逻辑改为点击切换（将当前行所有选项设为未选状态）
                  if (sub_type === EQuestionItemSubType.MATRIX_RADIO) {
                    newMatrixRows[rowIndex].options.forEach((colItem, colIndex) => {
                      if (newMatrixRows[rowIndex].options[colIndex].status !== EQuestionOptionStatus.UNSHOWED) {
                        newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED;
                      }
                    });
                    const newSelected = !selected;
                    newMatrixRows[rowIndex].options[colIndex].status = newSelected
                    ? EQuestionOptionStatus.CHOOSED
                    : EQuestionOptionStatus.UNCHOOSED;
                  } else {
                    if (selected) {
                      newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED
                    } else {
                      if (res.model.options[colIndex].exclusive) {
                        newMatrixRows[rowIndex].options.forEach(item => {
                          item.status = EQuestionOptionStatus.UNCHOOSED
                        })
                        newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.CHOOSED
                      } else {
                        newMatrixRows[rowIndex].options.forEach((item, i) => {
                          if (res.model.options[i].exclusive) {
                            item.status = EQuestionOptionStatus.UNCHOOSED
                          }
                        })
                        newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.CHOOSED
                      }
                    }
                  }
                  
                  ctx.emit('change', res, item);
                }
              }}
            >
              <div class={`multi-item ${selected ? 'selected' : ''}`}>
                <Row justify='center' align='middle' gutter={[8, 8]}>
                  <Col>
                    {text}
                  </Col>
                </Row>
              </div>
            </Col>;
          });
          const itemEle = <div class='w-100-p'>
            {subTitleEle}
            <div class='w-100-p multi-container-pop'>
              <Row gutter={[8, 8]}>
                {optionEles}
              </Row>
            </div>
          </div>;
          return [...res, itemEle];
        }, []);
      return <div class='task-question-item' id={docId.value}>
        {
          index.value !== 0
            ? <div class="divider"></div>
            : null
        }
        <div class='title'>
          <Row gutter={8}>
            <Col class='fs-16px fw-5'>{getTitleByQuestionItem(item.model, index.value)}</Col>
            <Col>{subTypeTitleEle.value}</Col>
            {
              max !== undefined && showError.value
                ? <Col><span class='error-max-tag cus-shake fs-14px'>每行最多选{max}项</span></Col>
                : null
            }
          </Row>
        </div>
        {/* 单选非必填情况则不需要显示tips */}
        {
          sub_type === EQuestionItemSubType.MATRIX_RADIO && !item.model.required
            ? null
            : getMaxTipsEle(max, item.model.required, '每行')
        }
        <div class='w-100-p'>
          {referEmptyEle.value ?? rowEles}
        </div>
      </div>;
    });
    async function validate(): Promise<IValidateResult> {
      return { result: true };
    }
    function validateRule(): IValidateResult {

      return { result: true };
    }
    return {
      ele,
      validate,
      validateRule,
    };
  },
  render() {
    return <div class={`survey-question-option-${this.value.model.id} question-matrix`}>
      {this.ele}
    </div>;
  },
});
