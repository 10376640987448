import { customRef, reactive, Ref, ref, set, UnwrapRef, watch } from '@vue/composition-api';

type IUseRefReturn<T> = [Ref<UnwrapRef<T>>, (newVal: T) => void];

export function useRef<T>(defaultVal?: T): IUseRefReturn<T> {
  const value = ref<T>(defaultVal as T);
  function setValue(newVal: T) {
    value.value = newVal as UnwrapRef<T>;
  }
  return [value, setValue];
}

export type GetKey<T> = keyof T;
export type GetValue<T> = T[keyof T];

type IResult<T extends Record<string, any>, K extends keyof T> = Pick<T, K>[K];
type IUseReactiveReturn<T extends Record<string, any>> = [
  UnwrapRef<T>,
  (<K extends keyof T>(key: K, newVal: IResult<T, K>) => void),
  (newVal: T) => void
];

export function useReactive<T extends Record<string, any>>(defaultVal: T): IUseReactiveReturn<T> {
  const value = reactive<T>(defaultVal);
  function setValue<K extends keyof T>(key: K, newVal: IResult<T, K>) {
    set(value, key, newVal);
  }
  // 批量设置
  function setBatchValue(newVal: T) {
    Object.keys(newVal).forEach((key) => {
      set(value, key, newVal);
    });
  }
  return [value, setValue, setBatchValue];
}

/**
 * 变量防抖
 * @param value
 * @param delay
 * @returns
 */
export function useDebounce<T>(value: T, delay = 200) {
  let timeout: any;
  return customRef((track, trigger) => {
    return {
      get() {
        track();
        return value;
      },
      set(newValue) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          value = newValue;
          trigger();
        }, delay);
      },
    };
  });
}

export function useDebounceFn<T extends(...rest: any[]) => any>(fn: T, delay = 200) {
  const debounceValue = useDebounce<number>(0, delay);
  let params: Parameters<T>;
  watch(
    debounceValue,
    () => {
      fn(...params);
    },
    { flush: 'sync' },
  );
  return function (...rest: Parameters<T>) {
    params = rest;
    debounceValue.value = debounceValue.value + 1;
  };
}

/**
 * 获取window相关信息
 * @returns
 */
export function useWindowInfo() {
  const height = useDebounce<number>(innerHeight, 10);
  const width = useDebounce<number>(innerWidth, 10);
  const [resizeCount, setResizeCount] = useRef<number>(0);
  const debouncedFn = useDebounceFn(() => {
    height.value = innerHeight;
    width.value = innerWidth;
    setResizeCount(resizeCount.value + 1);
  }, 10);
  window.addEventListener('resize', debouncedFn);
  return {
    height,
    width,
    resizeCount,
  };
}
