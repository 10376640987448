import { useRef } from '@/utils/composition-helper';
import { PropType, defineComponent, nextTick, onMounted, ref, toRefs, watch } from '@vue/composition-api';
import './index.less';

/**
 * QuestionOptionText 问题选项文本组件
 */
export default defineComponent({
  name: 'QuestionOptionText',
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    value: String,
    onChange: Function as PropType<(value: string) => void>,
  },
  setup(props, ctx) {
    const { value: propVal } = toRefs(props);
    const [content, setContent] = useRef<string | undefined>(props.value);
    const editableEle = ref<HTMLElement | null>(null);
    const saveSelection = () => {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        return selection.getRangeAt(0);
      }
      return null;
    };

    const restoreSelection = (range: Range | null) => {
      if (range) {
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    };

    const handleInput = (event: Event) => {
      const target = event.target as HTMLElement;
      const val = target.innerText;

      // 保存光标位置
      const range = saveSelection();

      // 更新内容
      ctx.emit('change', val);

      // 恢复光标位置
      nextTick(() => {
        restoreSelection(range);
      });
    };

    // 监听 props.value 的变化，并更新 content
    watch(() => propVal?.value, (newValue) => {
      if (newValue !== content.value) {
        setContent(newValue);
      }
    });

    const moveCaretToStart = (element: HTMLSpanElement) => {
      const range = document.createRange();
      const selection = window.getSelection();
      if (selection) {
        range.setStart(element.childNodes[0], 0);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    };

    const moveCaretToEnd = (element: HTMLSpanElement) => {
      const range = document.createRange();
      const selection = window.getSelection();
      if (selection) {
        range.selectNodeContents(element);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const eleRef = editableEle.value;
      if (!eleRef) return;

      switch (event.key) {
        case 'Home':
          // 将光标移动到文本的开头
          moveCaretToStart(eleRef);
          event.preventDefault();
          break;
        case 'End':
          // 将光标移动到文本的末尾
          moveCaretToEnd(eleRef);
          event.preventDefault();
          break;
        case 'Enter':
          // 回车换行禁用
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    onMounted(() => {
      if (editableEle.value) {
        editableEle.value.innerText = content.value ?? '';
        editableEle.value.addEventListener('keydown', handleKeyDown);
      }
    });
    return {
      content,
      editableEle,
      handleInput,
    };
  },
  render() {
    return <div
      ref='editableEle'
      class='question-option-text-component'
      contenteditable={!this.$props.disabled}
      onInput={this.handleInput}
    >
      {this.content}
    </div>;
  },
});
