import { useRef } from '@/utils/composition-helper';
import { ComponentInstance, PropType, defineComponent, onBeforeUnmount, onMounted } from '@vue/composition-api';
import _ from 'lodash';

/**
 * Col组件
 */
export default defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Col',
  props: {
    className: {
      type: String,
      default: '',
    },
    styleText: {
      type: String,
      default: '',
    },
    flex: [String, Number],
    lg: [Number, Object],
    offset: {
      type: Number,
      default: 0,
    },
    order: {
      type: Number,
      default: 0,
    },
    pull: {
      type: Number,
      default: 0,
    },
    push: {
      type: Number,
      default: 0,
    },
    sm: [Number, Object],
    span: {
      type: Number,
    },
    tag: {
      type: String,
      default: 'div',
    },
    xl: [Number, Object],
    xs: [Number, Object],
    xxl: [Number, Object],
    onClick: Function as PropType<(event: PointerEvent) => void>,
  },
  setup(props, ctx) {
    const [colRef] = useRef<ComponentInstance | null>(null);
    const handleClick = (event: any) => {
      ctx.emit('click', event);
    };
    onMounted(() => {
      // 只有传入了onClick事件才需要绑定点击事件，防止性能问题
      if (ctx.listeners.click && colRef.value?.$el) {
        colRef.value?.$el?.addEventListener?.('click', handleClick);
      }
    });
    onBeforeUnmount(() => {
      if (colRef.value?.$el) {
        colRef.value?.$el?.removeEventListener('click', handleClick);
      }
    });
    return {
      colRef,
    };
  },
  render() {
    const { span, flex } = this.$props;
    const currFlex = span === undefined && flex === undefined ? 'none' : flex;
    const props = _.omit(this.$props, 'flex');
    return <t-col
      ref='colRef'
      props={props}
      flex={currFlex}
      class={this.$props.className}
      style={this.$props.styleText}
    >{this.$slots.default}</t-col>;
  },
});
